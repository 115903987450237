import { configuration } from '../models/configuration'
import { get } from '../api'
import { AxiosResponse } from 'axios';
const controller = 'config'

export async function FindAll(): Promise<any> {
  const http = await get();
  const { data } = await http.get<any[], AxiosResponse>(`${controller}`);
  return data;
}


export async function FindOne( ): Promise<configuration> {
  const http = await get();
  const { data } = await http.get<configuration[], AxiosResponse>(`${controller}`);
  return data;
}

export async function Post(dado: configuration): Promise<configuration> {
  const http = await get();
  const { data } = await http.post<configuration, AxiosResponse>(`${controller}`, dado);
   return data;
}



