import { reactive, computed } from "vue";



const state = reactive<{ loading: boolean }>({
  loading: false,
});


const actions = {
  ActionSetPreloader(payload: boolean) {
    state.loading = payload;
  },
  time(payload: boolean) {
    setTimeout(() => {
      state.loading = payload;
    }, 800);
}


};
const getters = {
  isLoading: computed(() => state.loading),
};
export { getters, state, actions };
