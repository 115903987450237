import { ref, computed } from "vue";
import { LogInUser,refresh } from "@/core/services/auth.service";
import * as storage from "./storage"
// import * as Types from "@/types";
import { Credential } from "@/core/models/credential";
import swal from 'sweetalert2';
import { LoginResponse } from "@/core/models/loginResponse";
let token = localStorage.getItem("token");
if (token) token = JSON.parse(String(localStorage.getItem(token)));
const state = ref<LoginResponse>({});
const store = ref<any>({});
async function ActionSetUser(payload: LoginResponse) {
    state.value = payload;
    console.log("aqui")
    console.log(state.value)
    store.value = state.value.stores && state.value.stores.length ? state.value.stores[0] : undefined;
    storage.setLocalAdmUsuario(payload)
    storage.setLocalAdmToken(payload.access_token);
}
const actions = {
    login: async (credentials: Credential) => {
    try {
            const User = await LogInUser(credentials);
            ActionSetUser(User.body);
        } catch (error: any) {
            const message = error?.response?.data?.message;
            if (message == 'invalid_credentials')
                swal.fire({
                    icon: "warning",
                    text: "Usuário sem acesso ao sistema",
                    title: "Atenção"
                });
            throw new Error("Usuário sem acesso ao sistema")
        }
    },
    setUser: async (payload: any) => {
        ActionSetUser(payload);
    },

    logout: () => {
        localStorage.removeItem("token_user");
        localStorage.removeItem("usuario_adm");
        state.value = {};
        storage.deleteLocalAdmToken();
        storage.deleteLocalAdmUsuario();
    },
    refreshToken:async ()=>{
        const dados = await refresh(false);
        ActionSetUser(dados);
    },
    ActionCheckToken() {
        try {
            const token = storage.getLocalAdmToken();
            if (state.value.access_token) {
                return Promise.resolve(state.value.access_token);
            } else if (!token) {
                return this.logout()
            } else {
                ActionSetUser(storage.getLocalAdmUsuario()); ''
                return Promise.resolve(token);
            }
        } catch (error) {
            actions.logout();
        }
    },
    hasPermission(screen: string, action: string) {
        const scr: any = state.value.screens?.find(f => f.title == screen);
        return scr ? !!JSON.parse(scr.actions).find((f: string) => f == action) : false;

    }
};
const getters = {
    getStatusLoggedIn: computed(() => {
        return !!(state.value && state.value.access_token)
    }),
    getToken: computed(() => {
        return state.value.access_token;
    }),
    getUser: computed(() => {
        return state.value;
    }),
    getStoreId: computed(() => {
        return store.value;
    }),

    getStores: computed(() => {
        return state.value.stores;
    })

};
export { getters, state, actions, store };