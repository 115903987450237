import { createApp } from 'vue'
import App from './App.vue'
import PrimeVue from 'primevue/config';
import router from './router'
import 'bootstrap';
import vSelect from 'vue-select';
import useDropzone from 'vue3-dropzone';
import "@fontsource/playfair-display";
import "@fontsource/playfair-display/400.css";
import "@fontsource/playfair-display/400-italic.css";
import 'vue-select/dist/vue-select.css';
import 'primevue/resources/themes/lara-light-indigo/theme.css';
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

import { vMaska } from "maska"
import { Money3Directive } from 'v-money3'
import SimpleTypeahead from 'vue3-simple-typeahead';
import 'vue3-simple-typeahead/dist/vue3-simple-typeahead.css'; //Optional default CSS
import Vue3FormWizard from 'vue3-form-wizard'
import 'vue3-form-wizard/dist/style.css'
import VueGoogleMaps from '@fawmi/vue-google-maps'
import YouTube from 'vue3-youtube'
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel';
import 'jquery-countdown-timer';
import VueCountdown from '@chenfengyuan/vue-countdown';
import 'vue-easy-lightbox/dist/external-css/vue-easy-lightbox.css'

import VueClipboard from 'vue3-clipboard'
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'



// Vuetify
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import QRCodeVue3 from "qrcode-vue3";

const vuetify = createVuetify({
    components,
    directives,
})




const app = createApp(App);

app.use(router);
app.use(vuetify);
app.component('v-select', vSelect);
app.component('v-dropzone', useDropzone);
app.component('v-QRCodeVue3Example', QRCodeVue3);
 


app.component(VueCountdown.name, VueCountdown);
app.use(SimpleTypeahead);
app.use(PrimeVue);
app.component('VueDatePicker', VueDatePicker);
app.component('Vue3FormWizard', Vue3FormWizard);
app.component('YouTube', YouTube);
app.component('CoolLightBox', CoolLightBox);
app.directive("maska", vMaska)
app.directive('money3', Money3Directive)
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyD6gmsAXB07ZPsOPga5jtr8qil1ceBYwHQ',
        libraries: "places"
    },
});
app.use(VueClipboard, {
    autoSetContainer: true,
    appendToBody: true,
})
app.mount('#app');
