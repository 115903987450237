<template>
  <router-view />
  <AtomPreloader />
  <div
    class="modal fade"
    id="modalLogin"
    tabindex="-1"
    role="dialog"
    aria-labelledby="emodalLoginTitle"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="login-from-wrap">
            <form @submit.prevent="handleLogin">
              <div class="login-from p-5">
                <h1 class="site-title text-center">
                  <router-link to="/">
                    <img src="@/assets/travele-logo1.png" alt="" />
                  </router-link>
                </h1>
                <div class="form-group">
                  <label for="first_name1">Login</label>
                  <input
                    type="text"
                    class="validate input-t py-2"
                    v-model="dado.email"
                  />
                </div>
                <div class="form-group">
                  <label for="last_name">Senha</label>
                  <input
                    id="last_name"
                    type="password"
                    class="validate input-t py-2"
                    v-model="dado.password"
                  />
                </div>
                <div class="form-group">
                  <button
                    class="button-primary w-100 text-center"
                    data-dismiss="modal"
                    aria-label="Close"
                    @click="handleLogin"
                  >
                    Login
                  </button>
                </div>
                <div class="form-group">
                  <a
                    type="button"
                    class="button-primary w-100 text-center text-white"
                    data-toggle="modal"
                    data-target="#exampleModalCenter2"
                    data-dismiss="modal"
                    >Cadastrar-se</a
                  >
                  <!-- <router-link to="/admin" class="button-primary d-none w-100 text-center">Cadastrar-se</router-link> -->
                </div>
                <router-link
                  to="/esqueceu-senha"
                  class="remember for-pass text-end mb-2"
                  data-dismiss="modal"
                  >Esqueceu senha?</router-link
                ><br />
                <router-link
                  to="/admin/login"
                  class="remember for-pass text-end"
                  data-dismiss="modal"
                  >Entrar como Administrador</router-link
                >
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="exampleModalCenter2"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle2"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <div class="login-from-wrap">
            <form class="login-from p-5">
              <h1 class="site-title text-center">
                <router-link to="/">
                  <img src="@/assets/travele-logo1.png" alt="" />
                </router-link>
              </h1>
              <form class="form-horizontal" method="post">
                <div class="row">
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Nome</label>
                      <input
                        name="firstname"
                        class="form-control input-t"
                        type="text"
                        v-model="dadoClient.name"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Sobrenome</label>
                      <input
                        name="lastname"
                        class="form-control input-t"
                        type="text"
                        v-model="dadoClient.lastName"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Email</label>
                      <input
                        name="phone"
                        class="form-control input-t"
                        type="text"
                        v-model="dadoClient.email"
                      />
                    </div>
                  </div>
                  <div class="col-sm-6">
                    <div class="form-group">
                      <label>Celular</label>
                      <input
                        name="phone"
                        class="form-control input-t"
                        type="text"
                        v-model="dadoClient.cellphone"
                      />
                    </div>
                  </div>
                </div>
                <br />
                <a
                  type="button"
                  class="button-primary w-100 text-center text-white"
                  @click="save"
                  >Cadastrar</a
                >
              </form>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
  <button id="closeModal" style="display: none" data-toggle="modal"></button>
</template>
<script lang="ts" setup>
import AtomPreloader from "@/components/atoms/AtomPreloader.vue";
import Swal from "sweetalert2";
import { ref } from "vue";
import { client } from "./core/models/client";
import { Post } from "./core/services/client";
import { Auth } from "@/store";
import { Authentic } from "./core/models/authentification";
import router from "./router";
import { useRoute } from "vue-router";

const dado = ref<Authentic>({});
const dadoClient = ref<client>({});

function save() {
  const route = useRoute();
  const currentUrl = route.fullPath;
  dadoClient.value.url = currentUrl;

  console.log(currentUrl);

  Post(dadoClient.value as client)
    .catch((err) => {
      Swal.fire({
        title: "Ops..",
        text: `${err}`,
        icon: "warning",
      });
    })
    .then(() => {
      Swal.fire({
        title: "Cadastro realizado com sucesso!",
        text: "A senha foi enviada para o E-mail cadastrado.",
        icon: "success",
      }).then(() => {
        document.getElementById("closeModal")?.click();
      });
      dadoClient.value = {};
    });
}

async function handleLogin() {
  try {
    Auth.actions.login({
      email: dado.value.email,
      password: dado.value.password,
    });
    // .then(() => {
    //   router.push({ path: "/home-cliente" });
    // });
  } catch (er) {
    if (er) {
      Swal.fire({
        text: "Usuario ou Senha Incorretos",
        icon: "error",
      });
    }
  }
}
</script>

<style>
body {
  font-size: 15px;
  color: #383838;
  background-color: #ffffff;
  font-family: "Open Sans", sans-serif;
  font-weight: normal;
  font-style: normal;
  line-height: 1.6;
  -webkit-font-smoothing: antialiased;
  word-wrap: break-word;
}
.btn-link {
  text-decoration: none;
}
.btn-link:hover {
  text-decoration: none;
}
.btn-link:active {
  text-decoration: none;
}
.show {
  text-decoration: none;
}
.leaflet-div-icon {
  background-color: transparent !important;
  border: none !important;
}

/*--------------------------------------------------------------
# Heading
--------------------------------------------------------------*/
h1,
h2,
h3,
h4,
h5,
h6 {
  color: #033369;
  margin: 0 0 15px;
  line-height: 1.2;
  font-family: "Raleway", sans-serif;
}

h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}

h1 {
  font-size: 65px;
  font-weight: 900;
}

h2 {
  font-size: 48px;
  font-weight: 900;
}

h3 {
  font-size: 20px;
  font-weight: 700;
}

h4 {
  font-size: 18px;
  font-weight: 700;
}

h5 {
  font-size: 10px;
}

h6 {
  font-size: 12px;
}

@media screen and (max-width: 767px) {
  h1 {
    font-size: 32px;
  }

  h2 {
    font-size: 24px;
  }

  h3 {
    font-size: 18px;
  }

  h4 {
    font-size: 14px;
  }

  h5 {
    font-size: 14px;
  }

  h6 {
    font-size: 12px;
  }
}

/*--------------------------------------------------------------
# Lists
--------------------------------------------------------------*/
ul,
ol {
  margin: 0 0 1.5em 0.875em;
}

ul {
  list-style: disc;
}

ol {
  list-style: decimal;
}

li > ul,
li > ol {
  margin-bottom: 0;
  margin-left: 1.5em;
}

dt {
  font-weight: bold;
}

dd {
  margin: 0 1.5em 1.5em;
}

.pac-target-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/*--------------------------------------------------------------
# Links
--------------------------------------------------------------*/
</style>
