import { client } from '../models/client'
import { get } from '../api'
import { AxiosResponse } from 'axios';
import { clientPackage } from '../models/clientPackage';
const controller = 'client'

export async function FindAll(): Promise<client[]> {
    const http = await get();
    const { data } = await http.get<any[], AxiosResponse>(`${controller}`);
    return data;
  }
  
  export async function FindOne(id: number): Promise<client> {
    const http = await get();
    const { data } = await http.get<client[], AxiosResponse>(`${controller}/get/${id}`);
    return data;
  }
  export async function ForgotPassword(dado: client): Promise<client> {
    const http = await get();
    const { data } = await http.post<client, AxiosResponse>(`${controller}/Forgot-Password/`, dado);
    return data;
  }
  
  export async function Post(dado: client): Promise<client> {
    const http = await get();
    const { data } = await http.post<client, AxiosResponse>(`${controller}`, dado);
     return data;
  }
  
   export async function Put(dado: client ,id: number): Promise<client> {
    const http = await get();
  const { data } = await http.patch<client, AxiosResponse>(`${controller}/${id}`, dado);
     return data;
  }
  
  export async function Remove(id: number): Promise<client> {
    const http = await get();
    const { data } = await http.delete<client, AxiosResponse>(`${controller}/${id}`);
     return data;
  }
  

  export async function AllClientPackage(): Promise<clientPackage[]> {
    const http = await get();
    const { data } = await http.get<any[], AxiosResponse>(`${controller}/reservation`);
    return data;
  }

  
  export async function AllFinancial(): Promise<clientPackage[]> {
    const http = await get();
    const { data } = await http.get<any[], AxiosResponse>(`${controller}/Financial`);
    return data;
  }